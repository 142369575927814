<template>
    <v-layout row wrap class="store-single-wrapper">
        <v-flex md3>
            <store-filter :price_set="setPriceFilter"></store-filter>
        </v-flex>
        <v-flex xs9>
             <v-container style="max-height:250px; overflow: auto;">
                 <v-card class="pa-3 mb-5">
                     <v-card-title>
                         Tags
                     </v-card-title>
                     <v-card-text>
                         <v-row>
                             <v-col  v-for="tag in tags" cols="12" sm="3" md="3">
                                 <span v-bind:class="{'success--text': tag.active === true}"
                                       @click="select_tag(tag)">
                                     {{ tag.name }}
                                 </span>
                             </v-col>
                         </v-row>
                     </v-card-text>
                 </v-card>
             </v-container>

            <store-products :products="products" :user="user"></store-products>
            <div class="text-center" v-show="products.length">
                <v-pagination v-model="page"
                              circle
                              :total-visible="7"
                              :length="max_count"></v-pagination>
            </div>
            <div class="text-center" v-show="!products.length && init_load === false">
                <h3 class="display-2 text-center">
                    No Results Found
                </h3>
            </div>
            <div class="text-center" v-show="init_load !== false">
                <h3 class="display-2 text-center">
                    Searching Please Wait....
                </h3>
            </div>
        </v-flex>



    </v-layout>
</template>

<script>
    import StoreFilter from '../../components/store/StoreFilter'
    import StoreProducts from '../../components/store/Products'


    export default {
        components: {
            StoreFilter,
            StoreProducts,
        },
        name: "SearchPage",
        data: () => ({
            title: "Product",
            category:0,
            search_text:'',
            products:[],
            min_fob:0,
            max_fob: 9999,
            category_list: '',
            limit:12,
            page:1,
            offset:0,
            max_count:0,
            user:{ },
            tags: [],
            selected_tags: [],
            search_tags: '',
            init_load:true
        }),
        watch: {
            '$route.params.category_id': function (category_id) {
                this.compiled_load()
            },
            '$route.params.search_name': function (search_name) {
                this.compiled_load()
            },
            'selected_tags': function (tags) {
                this.load_store_products();
            },
            page(val) {
                this.load_store_products();
            },
        },
        created() {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.compiled_load();
            this.init_load = true;
        },
        methods: {
            select_tag(tag)
            {
                if(tag.active === true)
                {
                    // remove from selected
                    let index = this.selected_tags.indexOf(tag.id);
                    if (index >= 0) this.selected_tags.splice(index, 1);
                    tag.active=false;
                } else {
                    // add true and add to selected
                    tag.active = true;
                    this.selected_tags.push(tag.id);
                }
                this.$forceUpdate();
            },
            setPriceFilter(min, max)
            {
                this.min_fob = min;
                this.max_fob = max;
                this.compiled_load();

            },
            load_category_ids()
            {
                let search_url = this.$route.params.category_id;
                if (this.$route.params.category_id === 'itn')
                {
                    search_url = 0;
                }

                return this.$http.get(
                    this.$http.defaults.baseURL + '/store/cat-ids/' + search_url
                );

            },
            compiled_load() {
                this.load_category_ids().then((response) => {
                    console.log("CAT LIST!");
                    console.log(response);
                    this.category_list = response.data.cat_ids;
                    this.tags = response.data.tags;
                    this.load_store_products();
                }).catch((error) => {
                    this.notification = true;
                    this.notification_message = 'Error: Please contact administrator';
                    console.log("Error");
                    console.log(error);
                });
            },
            load_store_products()
            {
                this.category = this.$route.params.category_id;
                this.search_text = this.$route.params.search_name;
                let search_link = '/store/search/';


                //Add fob prices
                let min_fb = this.min_fob - ((this.min_fob / 100) * 30);
                let max_fb = this.max_fob - ((this.max_fob / 100) * 30);
                search_link += '?min_fob='+min_fb+'&max_fob='+max_fb;



                if(this.category !== '0' && this.category !== 'itn')
                {
                    search_link += '&category_id='+this.category_list;
                }
                if(this.search_text !== 'undefined' && this.category !== 'itn')
                {
                    search_link += '&search='+this.search_text;
                }

                search_link += '&limit='+this.limit;
                this.offset = (this.page - 1) * this.limit;
                search_link += '&offset='+this.offset;
                if(this.selected_tags.length > 0)
                {
                    search_link += '&tags='+this.selected_tags.join(',')
                }
                let cus_shop = localStorage.getItem('customer_store');
                if(cus_shop === 'true')
                {
                    let cus_dep_store = localStorage.getItem('customer_store_dep');
                    search_link += '&cus_store='+cus_dep_store
                }

                this.$http.get(this.$http.defaults.baseURL + search_link)
                    .then((response)=>{
                        console.log(response);
                        this.products = response.data.results;
                        this.max_count = Math.round(parseFloat((response.data.count / this.limit) + 0.4));
                        console.log(this.max_count);
                        console.log(response.data.count );
                        this.init_load = false;
                    })
                    .catch((error)=>{
                        console.log("search page error");
                        console.log(error);
                    });
            }
        }
    }
</script>

<style scoped>

</style>
